<template>
  <v-card class="ma-4">
    <v-toolbar :color="selectedItems.length ? 'grey darken-4' : '#335D6E'" dark
               elevation="1">

      <v-toolbar-title> {{ selectedItems.length ? `Selected (${selectedItems.length})  rows` : title }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-text-field
          v-if="!selectedItems.length"
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          filled
          hide-details
          label="Search about something"
          single-line
      ></v-text-field>

      <v-btn
          v-if="selectedItems.length"
          color="grey darken-2"
          dark
          @click.stop="restoreItems"
      >Restore
      </v-btn>

      <v-dialog v-if="!selectedItems.length" v-model="dialogAddItem" max-width="750px">

        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mx-11"
              color="#082d3c"
              dark
              v-bind="attrs"
              v-on="on"
          >New
          </v-btn>
        </template>

        <v-card v-if="dialogAddItem">
          <v-toolbar
              color="blue-grey"
              dark
          >
            <v-btn
                dark
                icon
                @click="dialogAddItem = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  :disabled="(!valid || buttonLoading)"
                  :loading="buttonLoading"
                  dark
                  text
                  @click="validate"
              >Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-alert
              v-if="Object.keys(errors).length>0"
              dense
              text
              type="error"
          >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
              <li
                  v-for="(error, index) in errors"
                  :key="index"
              >{{ error.message || error }}
              </li>
            </ul>
          </v-alert>

          <v-card-text>
            <v-container>

              <v-form
                  ref="form"
                  v-model="valid"
                  :disabled="buttonLoading"
                  lazy-validation
              >
                <v-row>

                  <v-col cols="12">
                    <v-select
                        v-model="user"
                        :items="users"
                        hide-details
                        item-text="name"
                        item-value="id"
                        label="Customer Name"
                        outlined
                        return-object
                        @change="changeUser"
                    >
                      <template v-slot:prepend-item>
                        <v-text-field v-model="searchUserTxt" class="ma-4" dense outlined placeholder="Search for ..."
                                      @input="searchUsers"></v-text-field>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                        v-model="titleName"
                        :items="titleNames"
                        hide-details
                        item-text="name"
                        item-value="id"
                        label="Select from menu"
                        outlined
                        return-object
                    ></v-select>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field v-model="name" :rules="[v => !!v || 'name is required']" clearable label="Name"
                                  outlined required></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field v-model="email" :rules="[v => !!v || 'email is required']" label="Email"
                                  outlined required></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="mobile" :rules="[v => !!v || 'mobile is required']" clearable
                                  label="Mobile" maxlength="11"
                                  outlined @keypress="onlyNumber"></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field v-model="phone" clearable
                                  label="Phone" maxlength="11"
                                  outlined @keypress="onlyNumber"></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                        v-model="suggestionBillingAddress"
                        :items="itemsBillingAddress"
                        :loading="isLoadingBillingAddress"
                        :search-input.sync="billingSearch"
                        append-icon="mdi-magnify"
                        clearable
                        hide-selected
                        hideNoData
                        item-text="suggestion"
                        item-value="id"
                        label="Billing address Auto Select"
                        no-filter
                        outlined
                        placeholder="Start typing to search for billing address"
                        return-object
                        single-line
                        @click:append="billingSearchPostcode"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="city" :rules="[v => !!v || 'Please insert city']"
                                  label="Billing city"
                                  outlined required></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="postcode" :rules="postcodeRules" label="Billing Postcode"
                                  outlined required></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="addressLine1" :rules="addressLine1Rules"
                                  label="Billing Address Line 1"
                                  outlined required></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="addressLine2" :rules="addressLine2Rules"
                                  label="Billing Address Line 2"
                                  outlined></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox
                        v-model="isSameAddress"
                        color="red"
                        label="Is install address same billing address ?"
                    ></v-checkbox>
                  </v-col>

                  <v-col v-if="!isSameAddress" cols="12">
                    <v-autocomplete
                        v-model="suggestionInstallAddress"
                        :items="itemsInstallAddress"
                        :loading="isLoadingInstallAddress"

                        :search-input.sync="installSearch"
                        append-icon="mdi-magnify"
                        clearable
                        hide-selected
                        hideNoData
                        item-text="suggestion"
                        item-value="id"
                        label="Install address Auto Select"
                        no-filter
                        outlined
                        placeholder="Start typing to search for install address"
                        return-object
                        single-line
                        @click:append="installSearchPostcode"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="installCity" :rules="[v => !!v || 'Please insert city']"
                                  label="City"
                                  outlined required></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="installPostcode" :rules="postcodeRules" label="Postcode"
                                  outlined required></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="installLine1" :rules="addressLine1Rules" label="Address Line 1"
                                  outlined required></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="3" md="3" sm="6">
                    <v-text-field v-model="installLine2" :rules="addressLine2Rules" label="Address Line 2"
                                  outlined></v-text-field>
                  </v-col>


                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-btn
          v-else
          class="mx-11"
          color="grey darken-2"
          dark
          @click.stop="showDialogDelete"
      >Delete
      </v-btn>
      <v-btn
          v-if="selectedItems.length"
          icon
          @click="selectedItems = []"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-menu
          v-else
          :close-on-content-click="false"
          :nudge-width="250"
          offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isShowDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Show deleted data</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isForceDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Delete data forever</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>

    <v-card-text>
      <v-dialog
          v-model="dialogDelete"
          max-width="425"
          persistent
      >
        <v-card>
          <v-card-title>
            Delete group of data ({{ selectedItems.length ? selectedItems.length : 1 }}) rows
          </v-card-title>

          <v-card-text class=" ">
            Are you sure for continue deleting these data ?
          </v-card-text>

          <v-card-actions>
            <v-checkbox
                v-model="isForceDeletedRow"
                :disabled=disableChangeDelete
                :ripple=false
                class="ma-2 red--text "
                color="red"
                hide-details
            >
              <template v-slot:label>
                <div class="red--text body-2">
                  Delete data forever
                </div>
              </template>
            </v-checkbox>

            <v-spacer></v-spacer>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="primary"
                text
                @click="hideDialogDelete()"
            >
              Reject
            </v-btn>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="red darken-2"
                text
                @click="destroyItems"
            >
              Process
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-if="!dialogDelete && !dialogAddItem"
          v-model="buttonLoading"
          hide-overlay
          persistent
          width="300"
      >
        <v-card
            color="indigo"
            dark
        >
          <v-card-text>
            <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

            <v-progress-linear
                class="my-2"
                color="white"
                indeterminate
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-data-table
          v-model="selectedItems"
          :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
          :headers="headers"
          :item-class="(item)=>item.deletedAt?'deletedRow':null"
          :items="desserts"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :search="search"
          checkbox-color="'blue'"
          item-key='id'
          show-select
      >


        <template v-slot:header.data-table-select="{props,on}">
          <v-simple-checkbox :ripple=false color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
        </template>
        <template v-slot:item.data-table-select="{isSelected,select}">
          <v-simple-checkbox :ripple=false :value="isSelected" color="primary" @input="select($event)"></v-simple-checkbox>
        </template>
        <template v-slot:item.id="{ item }">
          <v-chip>{{ item.id }}</v-chip>
        </template>
        <template v-slot:item.deletedAt="{ item }">
          <v-icon
              v-if="item.deletedAt"
              class="mr-3"
              color="primary"
              small
              @click="restoreItems(item)"
          >mdi-restore
          </v-icon>


          <v-chip
              color="indigo"
              label
              outlined
              small
              @click="editItem(item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-chip>


          <v-chip
              :color="item.deletedAt ?'orange':'red accent-4'"
              class="ml-3"
              label
              outlined
              small
              @click="showDialogDelete(item)"
          >
            <v-icon small>
              {{ item.deletedAt ? 'mdi-delete-clock' : ' mdi-delete' }}
            </v-icon>
          </v-chip>

        </template>

        <template v-slot:item.User.name="{ item }">
          <v-chip color="primary" dark outlined>{{ item.User?.name??" " }}</v-chip>
        </template>


        <template v-slot:item.isActive="{ item }">
          <v-icon :color="(item.isActive)?'green':'red'">
            mdi-eye
          </v-icon>
        </template>


      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {roleCustomers, urlCreateCustomer, urlCustomers, urlPostcodeApi, urlPostcodeAutoComplete, urlPostcodeKey, urlUpdateCustomer, urlUsersBasedRole} from "@/config";
import networks from "@/services/networks";

export default {
    data: () => ({
      search: null,
      dialogDelete: false,
      disableChangeDelete: false,
      deleteItemIDs: [],
      selectedItems: [],
      valid: true,
      id: 0,
      name: null,
      email: null,
      emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid Email address'],
      phone: null,
      mobile: null,
      titleName: null,
      titleNames: [{id: 1, name: "Mr"}, {id: 2, name: "Mrs"}, {id: 3, name: "Ms"}, {id: 4, name: "Miss"}, {
        id: 5,
        name: "Dr"
      },],


      entriesInstallAddress: [],
      isLoadingInstallAddress: false,
      suggestionInstallAddress: null,
      installSearch: null,
      urlSelectedInstallAddress: null,
      selectedInstallAddress: null,

      installLine1: null,
      installLine2: null,
      installCity: null,
      installPostcode: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      postcode: null,
      isSameAddress: false,
      entriesBillingAddress: [],
      isLoadingBillingAddress: false,
      suggestionBillingAddress: null,
      billingSearch: null,
      urlSelectedBillingAddress: null,
      selectedBillingAddress: null,


      postcodeRules: [
        v => !!v || 'postcode is required',
      ],
      addressLine1Rules: [
        v => !!v || 'address line 1 is required',
        v => (v && v.length <= 150) || 'address line 1 must be less than 150 characters',
      ],
      addressLine2Rules: [
        v => (!v || v.length <= 150) || 'address line 2 must be less than 150 characters',
      ],


      isActive: true,
      user: null,
      users: [],
      originUsers: [],
      searchUserTxt: null,

      errors: [],
      buttonLoader: 'buttonLoading',
      buttonLoading: false,
      dialogAddItem: false,

      title: "Customer",

      options: null,
      desserts: [],
      loading: true,
      headers: [
        {text: '#', value: 'id'},
        {text: 'Name', value: 'User.name', align: 'center'},
        {text: 'Billing City', value: 'city', align: 'center'},
        {text: 'Billing Postcode', value: 'postcode', align: 'center'},
        {text: 'Mobile', value: 'User.username', align: 'center'},
        {text: 'Status', value: 'isActive', align: 'center'},
        {text: 'Actions', value: 'deletedAt', sortable: false, align: 'right'},
      ],
      isFormTitleEdit: false,

    }),

    created() {
      this.getCustomersFromApi();
      this.getUsersFromApi();

    },


    computed: {

      formTitle() {
        return this.isFormTitleEdit ? 'Edit customer info' : 'Add new customer'
      },

      itemsInstallAddress() {
        return this.entriesInstallAddress.map(entry => {
          const suggestion = entry.suggestion;
          return Object.assign({}, entry, {suggestion})
        })
      },
      itemsBillingAddress() {
        return this.entriesBillingAddress.map(entry => {
          const suggestion = entry.suggestion;
          return Object.assign({}, entry, {suggestion})
        })
      },
      isShowDeleted: {
        get() {
          return this.$store.getters.isShowDeleted;
        },
        set(val) {
          this.$store.state.isShowDeleted = val;
          this.getCustomersFromApi();
          return val;
        }


      },
      isForceDeleted: {
        get() {
          return this.$store.getters.isForceDeleted;
        },
        set(val) {
          this.isForceDeletedRow = val;
          this.$store.state.isForceDeleted = val;
          return val;
        }
      },
      isForceDeletedRow: {
        get() {
          return this.$store.getters.isForceDeleted;
        },
        set(val) {
          this.$store.state.isForceDeleted = val;
          return val;
        }
      },
    },

    watch: {

      dialogAddItem() {
        if (!this.dialogAddItem) {
          this.isFormTitleEdit = false;
          this.clear();
        }
      },

      suggestionInstallAddress() {
        if (!this.suggestionInstallAddress) return null;

        this.urlSelectedInstallAddress = this.suggestionInstallAddress.urls.udprn;
      },

      urlSelectedInstallAddress() {

        if (this.isLoadingInstallAddress) return;

        this.isLoadingInstallAddress = true;

        fetch(urlPostcodeApi + this.urlSelectedInstallAddress + '?' + urlPostcodeKey)
            .then(res => res.json())
            .then(res => {
              this.selectedInstallAddress = res.result;
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoadingInstallAddress = false))
      },

      suggestionBillingAddress() {
        if (!this.suggestionBillingAddress) return null;

        this.urlSelectedBillingAddress = this.suggestionBillingAddress.urls.udprn;
      },

      urlSelectedBillingAddress() {

        if (this.isLoadingBillingAddress) return;

        this.isLoadingBillingAddress = true;

        fetch(urlPostcodeApi + this.urlSelectedBillingAddress + '?' + urlPostcodeKey)
            .then(res => res.json())
            .then(res => {
              this.selectedBillingAddress = res.result;
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoadingBillingAddress = false))
      },

      selectedInstallAddress() {
        if (this.selectedInstallAddress) {
          this.installLine1 = this.selectedInstallAddress.line_1;
          this.installLine2 = this.selectedInstallAddress.line_2;
          this.installCity = this.selectedInstallAddress.post_town;
          this.installPostcode = this.selectedInstallAddress.postcode;
        }
      },

      selectedBillingAddress() {
        if (this.selectedBillingAddress) {
          this.addressLine1 = this.selectedBillingAddress.line_1;
          this.addressLine2 = this.selectedBillingAddress.line_2;
          this.city = this.selectedBillingAddress.post_town;
          this.postcode = this.selectedBillingAddress.postcode;

        }
      },
      isSameAddress() {
        if (this.isSameAddress) {
          this.installLine1 = this.addressLine1;
          this.installLine2 = this.addressLine2;
          this.installCity = this.city;
          this.installPostcode = this.postcode;
        } else {
          this.installLine1 = null;
          this.installLine2 = null;
          this.installCity = null;
          this.installPostcode = null;
        }
      },
    },

    methods: {
      getCustomersFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlCustomers + this.isShowDeleted)
            .then(response => {
              this.desserts = response.data;
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });


      },

      getUsersFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlUsersBasedRole + roleCustomers)
            .then(response => {
              this.users = this.originUsers = response.data;
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });


      },

      billingSearchPostcode() {
        if (this.isLoadingBillingAddress) return;

        this.isLoadingBillingAddress = true;

        fetch(urlPostcodeApi + urlPostcodeAutoComplete + this.billingSearch + '&' + urlPostcodeKey)
            .then(res => res.json())
            .then(res => {
              this.entriesBillingAddress = res.result.hits;
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoadingBillingAddress = false));
      },

      installSearchPostcode() {
        if (this.isLoadingInstallAddress) return;

        this.isLoadingInstallAddress = true;

        fetch(urlPostcodeApi + urlPostcodeAutoComplete + this.installSearch + '&' + urlPostcodeKey)
            .then(res => res.json())
            .then(res => {
              this.entriesInstallAddress = res.result.hits;
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoadingInstallAddress = false));
      },


      changeUser(user) {
        this.name = user.name;
        this.email = user.email;
        this.mobile = user.username;
      },

      searchUsers() {
        if (!this.searchUserTxt) {
          this.users = this.originUsers;
        }
        this.users = this.originUsers.filter((user) => {
          return user.name.toLowerCase().indexOf(this.searchUserTxt.toLowerCase()) > -1;
        });
      },

      onlyNumber($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
        }

      },

      validate() {
        if (this.$refs.form.validate()) {
          this.buttonLoading = true;
          this.errors = [];
          this.saveItem();
        }
      },

      saveItem() {
        let data = {
          title: this.titleName?.id ?? null,
          userID: this.user?.id ?? null,
          name: this.name,
          email: this.email,
          phone: this.phone,
          mobile: this.mobile,
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          city: this.city,
          postcode: this.postcode,
          installLine1: this.installLine1,
          installLine2: this.installLine2,
          installCity: this.installCity,
          installPostcode: this.installPostcode,
          isActive: this.isActive,
          isSameInstall: this.isSameAddress,
        };


        if (this.isFormTitleEdit)
          networks.fetchFromWeb(urlUpdateCustomer + this.id, 2, data)
              .then(response => {

                if (response.status === 200) {
                  this.clear();
                  this.dialogAddItem = false;
                  alert("the data has been edited successfully");
                  this.getCustomersFromApi();
                }


              })
              .catch(error => {
                this.errors = error.response.data.errors || error.response.data.error;
                this.buttonLoading = false;
              });
        else {

          networks.fetchFromWeb(urlCreateCustomer, 1, data)
              .then(response => {

                if (response.status == 200) {
                  this.clear();
                  this.dialogAddItem = false;
                  alert("the data has been added successfully");
                  this.getCustomersFromApi();
                }


              })
              .catch(error => {
                this.errors = error.response.data.errors || error.response.data.error;
                this.buttonLoading = false;

              });
        }

      },

      clear() {
        if (this.$refs.form)
          this.$refs.form.resetValidation();

        this.name = null;
        this.id = 0;
        this.titleName = null;
        this.email = null;
        this.mobile = null;
        this.phone = null;
        this.isActive = true;
        this.user = null;
        this.entriesInstallAddress = [];
        this.isLoadingInstallAddress = false;
        this.suggestionInstallAddress = null;
        this.installSearch = null;
        this.urlSelectedInstallAddress = null;
        this.selectedInstallAddress = null;
        this.entriesBillingAddress = [];
        this.isLoadingBillingAddress = false;
        this.suggestionBillingAddress = null;
        this.billingSearch = null;
        this.urlSelectedBillingAddress = null;
        this.selectedBillingAddress = null;
        this.city = null;
        this.addressLine1 = null;
        this.addressLine2 = null;
        this.postcode = null;
        this.installLine1 = null;
        this.installLine2 = null;
        this.installCity = null;
        this.installPostcode = null;
        this.isSameAddress = false;
        this.valid = true;
        this.errors = [];
        this.buttonLoading = false;
      },


      editItem(item) {
        this.clear();
        this.isFormTitleEdit = true;
        this.id = item.id;
        this.user = item.User;
        this.name = item.User?.name;
        this.email = item.User?.email;
        this.mobile = item.mobile;
        this.phone = item.phone;
        this.titleName = {id: item.title};
        this.installDate = item.installDate;
        this.selectedInstallAddress = {
          line_1: item.installLine1,
          line_2: item.installLine2,
          post_town: item.installCity,
          postcode: item.installPostcode,
        };
        this.selectedBillingAddress = {
          line_1: item.addressLine1,
          line_2: item.addressLine2,
          post_town: item.city,
          postcode: item.postcode,
        };
        this.isSameAddress = item.isSameInstall ?? false;

        this.isActive = item.isActive;
        this.dialogAddItem = true;
      },

      showDialogDelete(item) {
        if (this.selectedItems.length < 1) {
          if (!item.deletedAt || (item.deletedAt && this.isForceDeleted)) {
            this.disableChangeDelete = item.deletedAt;
            this.dialogDelete = true;
            this.deleteItemIDs.push(item.id);
          }
        } else {
          this.dialogDelete = true;
        }

      },
      hideDialogDelete() {
        this.dialogDelete = false;
        this.disableChangeDelete = false;
        this.deleteItemIDs = [];
        this.selectedItems = [];
      },
      destroyItems() {

        this.buttonLoading = true;
        if (this.selectedItems.length > 0) {
          let currentSelected = [];

          if (this.isForceDeletedRow)
            currentSelected = this.selectedItems;
          else
            currentSelected = this.selectedItems.filter((e) => e.deletedAt === false);


          currentSelected.map((e) => this.deleteItemIDs.push(e.id));
        }


        if (this.deleteItemIDs.length < 1) {
          this.hideDialogDelete();
          this.buttonLoading = false
        } else {
          networks.fetchFromWeb(urlCustomers + this.deleteItemIDs + "/" + this.isForceDeletedRow, 3)
              .then(response => {

                if (response.status == 200) {
                  this.hideDialogDelete();
                  alert("Data has been deleted");
                  this.getCustomersFromApi();
                }

              })
              .catch(() => {
                alert("Failed for process delete data");
                this.hideDialogDelete();
              }).finally(() => this.buttonLoading = false);
        }

      },

      restoreItems(item) {
        this.buttonLoading = true;
        if (this.selectedItems.length > 0) {
          let currentSelected = [];
          currentSelected = this.selectedItems.filter((e) => e.deletedAt === true);
          currentSelected.map((e) => this.deleteItemIDs.push(e.id));

        } else {
          this.deleteItemIDs.push(item.id);
        }

        if (this.deleteItemIDs.length < 1) {
          this.hideDialogDelete();
          this.buttonLoading = false;
        } else {

          networks.fetchFromWeb(urlCustomers + this.deleteItemIDs + "/" + this.isForceDeletedRow + "/true", 3)
              .then(response => {

                if (response.status == 200) {
                  this.hideDialogDelete();

                  this.getCustomersFromApi();
                }

              })
              .catch(() => {
                alert("Failed for process delete data");
                this.hideDialogDelete();
              }).finally(() => this.buttonLoading = false);

        }
      }

    },
  }
</script>

<style>
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0deg) !important;
}
</style>


